// Simple Perlin Noise implementation
let perm = [];
const grad3 = [[1,1,0],[-1,1,0],[1,-1,0],[-1,-1,0],[1,0,1],[-1,0,1],[1,0,-1],[-1,0,-1],[0,1,1],[0,-1,1],[0,1,-1],[0,-1,-1]];

// Initialize Perlin noise
for (let i = 0; i < 256; i++) perm[i] = i;
perm = perm.sort(() => Math.random() - 0.5);
perm = [...perm, ...perm];

const fade = t => t * t * t * (t * (t * 6 - 15) + 10);
const lerp = (a, b, t) => (1 - t) * a + t * b;
const grad = (hash, x, y) => {
  const g = grad3[hash % 12];
  return g[0] * x + g[1] * y;
};

export function noise(x, y) {
  const X = Math.floor(x) & 255, Y = Math.floor(y) & 255;
  x -= Math.floor(x);
  y -= Math.floor(y);
  const u = fade(x), v = fade(y);
  const aa = perm[X] + Y, ab = perm[X] + Y + 1;
  const ba = perm[X + 1] + Y, bb = perm[X + 1] + Y + 1;

  return lerp(
    lerp(grad(perm[aa], x, y), grad(perm[ba], x - 1, y), u),
    lerp(grad(perm[ab], x, y - 1), grad(perm[bb], x - 1, y - 1), u),
    v
  );
}
