import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { noise } from "./perlin";

const API_URL = "/gpsDifference";

const ContourPlot = () => {
    const svgRef = useRef();
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [clickedCoords, setClickedCoords] = useState(null);
    const [gpsDifference, setGpsDifference] = useState(null);
    const [lowestGPS, setLowestGPS] = useState(null);
    const [highestGPS, setHighestGPS] = useState(null);

    useEffect(() => {
        // nice try
        // you might be reading, but I might not be writing.
        // Still, if you know your way around..
        fetch(API_URL, {
            headers: {
                "x-api-auth": "cogjih-padcix-7baVhu"
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to fetch GPS data");
                }
                return response.json();
            })
            .then(data => {
                setGpsDifference(data.gpsDifference);

                const baseGPS = {
                    lat: Math.random() * 100,
                    lon: Math.random() * 100,
                };

                setLowestGPS(baseGPS);
                setHighestGPS({
                    lat: baseGPS.lat + data.gpsDifference.lat,
                    lon: baseGPS.lon + data.gpsDifference.lon,
                });
            })
            .catch(error => console.error("Error fetching GPS difference:", error));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (!gpsDifference || !lowestGPS || !highestGPS) return;

        const { width, height } = size;
        const resolution = Math.max(4, Math.min(width / 150, 100));

        d3.select(svgRef.current).selectAll("*").remove();

        const svg = d3.select(svgRef.current)
            .attr("width", width)
            .attr("height", height);

        const cols = Math.min(250, Math.floor(width / resolution));
        const rows = Math.min(250, Math.floor(height / resolution));

        let values = new Array(cols * rows).fill(0).map((_, i) => {
            const x = (i % cols) / cols;
            const y = Math.floor(i / cols) / rows;
            return noise(x * 3, y * 3);
        });

        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);

        const levels = 40;
        const thresholds = d3.range(minValue, maxValue, (maxValue - minValue) / levels);
        const contours = d3.contours()
            .size([cols, rows])
            .thresholds(thresholds)
            (values);

        const colorScale = d3.scaleSequential()
            .domain([minValue, maxValue])
            .interpolator(t => d3.interpolateGreys((1 - t) * 0.7 + 0.6));

        const latBins = d3.scaleLinear()
            .domain([minValue, maxValue])
            .range([lowestGPS.lat, highestGPS.lat]);

        const lonBins = d3.scaleLinear()
            .domain([minValue, maxValue])
            .range([lowestGPS.lon, highestGPS.lon]);

        const clampGPS = (lat, lon) => ({
            lat: Math.max(lowestGPS.lat, Math.min(highestGPS.lat, lat)),
            lon: Math.max(lowestGPS.lon, Math.min(highestGPS.lon, lon))
        });

        let colorMap = new Map();
        contours.forEach((contour, i) => {
            const roundedValue = Math.round(contour.value * 1000) / 1000;
            if (!colorMap.has(roundedValue)) {
                const lat = latBins(contour.value);
                const lon = lonBins(contour.value);
                colorMap.set(roundedValue, clampGPS(lat, lon));
            }
        });

        const minThreshold = minValue + (maxValue - minValue) * 0.05;
        const maxThreshold = maxValue - (maxValue - minValue) * 0.05;

        svg.selectAll("path")
            .data(contours)
            .enter().append("path")
            .attr("d", d3.geoPath(d3.geoIdentity().scale(resolution)))
            .attr("fill", d => colorScale(d.value))
            .attr("stroke", "#222")
            .attr("stroke-width", 0.5)
            .attr("opacity", 0.95)
            .on("click", (event, d) => {
                let gps;
                const roundedValue = Math.round(d.value * 1000) / 1000;

                if (d.value <= minThreshold) {
                    gps = lowestGPS;
                } else if (d.value >= maxThreshold) {
                    gps = highestGPS;
                } else {
                    gps = colorMap.get(roundedValue) || lowestGPS;
                }

                let tooltipX = event.clientX + 20;
                let tooltipY = event.clientY + 10;
                const tooltipWidth = 150;
                const tooltipHeight = 50;
                if (tooltipX + tooltipWidth > window.innerWidth) tooltipX = event.clientX - tooltipWidth - 10;
                if (tooltipY + tooltipHeight > window.innerHeight) tooltipY = event.clientY - tooltipHeight - 10;

                setClickedCoords({
                    lat: gps.lat,
                    lon: gps.lon,
                    x: event.clientX,
                    y: event.clientY,
                    tooltipX,
                    tooltipY
                });
            });

    }, [size, gpsDifference, lowestGPS, highestGPS]);

    if (!gpsDifference || !lowestGPS || !highestGPS) {
        return <p>Loading map...</p>;
    }

    return (
        <div>
            <svg ref={svgRef} style={{ display: "block" }}>
                {clickedCoords && (
                    <circle
                        cx={clickedCoords.x}
                        cy={clickedCoords.y}
                        r="6"
                        fill="red"
                        stroke="white"
                        strokeWidth="2"
                    />
                )}
            </svg>
            {clickedCoords && (
                <div style={{
                    position: "absolute",
                    left: clickedCoords.tooltipX,
                    top: clickedCoords.tooltipY,
                    background: "white",
                    padding: "5px",
                    border: "1px solid black",
                    borderRadius: "5px",
                    fontSize: "14px"
                }}>
                    Lat: {clickedCoords.lat.toFixed(5)}, Lon: {clickedCoords.lon.toFixed(5)}
                </div>
            )}
        </div>
    );
};

export default ContourPlot;
